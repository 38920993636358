<template>
  <v-skeleton-loader :loading="status.reading" type="article">
    <v-sheet v-bind="propsCompSheet" data-cy="users-delete">
      <div class="mb-4">
        <v-form :disabled="formDisabled" @submit.prevent>
          <div class="formField">
            <span class="reqMark">*</span>
            <v-text-field
              v-bind="propsFormFields"
              :label="$t('common.userName')"
              v-model="editUserName"
              :error-messages="editUserNameErrors"
              @input="$v.editUserName.$touch()"
              @blur="$v.editUserName.$touch()"
              type="text"
            >
            </v-text-field>
          </div>

          <div class="formField">
            <span class="reqMark">*</span>
            <v-text-field
              v-bind="propsFormFields"
              :label="$t('common.name')"
              v-model="editName"
              :error-messages="editNameErrors"
              @input="$v.editName.$touch()"
              @blur="$v.editName.$touch()"
              type="text"
            >
            </v-text-field>
          </div>

          <div class="formField">
            <v-text-field
              v-bind="propsFormFields"
              :label="$t('common.surnames')"
              v-model="editSurnames"
              :error-messages="editSurnamesErrors"
              @input="$v.editSurnames.$touch()"
              @blur="$v.editSurnames.$touch()"
              type="text"
            >
            </v-text-field>
          </div>

          <div class="formField">
            <UserRolePickerSelect
              :disabled="
                !authCanOthersByUserId('editOthersRole.users', this.objectId)
              "
              :pickerValue="editRole"
              @pickerChanged="editRole = $event"
            >
            </UserRolePickerSelect>
          </div>

          <div class="formField">
            <v-text-field
              disabled
              v-bind="propsFormFields"
              :label="$t('common.email')"
              v-model="editEmail"
              :error-messages="editEmailErrors"
              @input="$v.editEmail.$touch()"
              @blur="$v.editEmail.$touch()"
              type="text"
            >
            </v-text-field>
          </div>

          <div class="formField">
            <v-text-field
              v-bind="propsFormFields"
              :label="$t('common.phone')"
              v-model="editPhone"
              :error-messages="editPhoneErrors"
              @input="$v.editPhone.$touch()"
              @blur="$v.editPhone.$touch()"
              type="text"
            >
            </v-text-field>
          </div>

          <div class="formField">
            <v-text-field
              v-bind="propsFormFields"
              :label="$t('common.profilePicture')"
              v-model="editProfilePicturePath"
              :error-messages="editProfilePicturePathErrors"
              @input="$v.editProfilePicturePath.$touch()"
              @blur="$v.editProfilePicturePath.$touch()"
              type="text"
              disabled
            >
            </v-text-field>
          </div>

          <div class="formField">
            <v-textarea
              v-bind="propsFormFields"
              :label="$t('common.description')"
              v-model="editDescription"
              :error-messages="editDescriptionErrors"
              @input="$v.editDescription.$touch()"
              @blur="$v.editDescription.$touch()"
              type="text"
            >
            </v-textarea>
          </div>

          <div class="formField">
            <v-text-field
              v-bind="propsFormFields"
              :label="$t('common.address')"
              v-model="editAddress"
              :error-messages="editAddressErrors"
              @input="$v.editAddress.$touch()"
              @blur="$v.editAddress.$touch()"
              type="text"
            >
            </v-text-field>
          </div>

          <div class="formField">
            <v-text-field
              v-bind="propsFormFields"
              :label="$t('common.city')"
              v-model="editCity"
              :error-messages="editCityErrors"
              @input="$v.editCity.$touch()"
              @blur="$v.editCity.$touch()"
              type="text"
            >
            </v-text-field>
          </div>

          <div class="formField">
            <v-text-field
              v-bind="propsFormFields"
              :label="$t('common.postalCode')"
              v-model="editPostalCode"
              :error-messages="editPostalCodeErrors"
              @input="$v.editPostalCode.$touch()"
              @blur="$v.editPostalCode.$touch()"
              type="text"
            >
            </v-text-field>
          </div>

          <div class="formField">
            <CountryPickerSelect
              :pickerValue="editCountryIso3166_a2"
              @pickerChanged="editCountryIso3166_a2 = $event"
            >
            </CountryPickerSelect>
          </div>

          <div class="formField">
            <LanguagePickerSelect
              :pickerValue="editLanguageIso639_1"
              @pickerChanged="editLanguageIso639_1 = $event"
            >
            </LanguagePickerSelect>
          </div>
        </v-form>
      </div>

      <div class="d-flex">
        <v-btn
          v-if="
            !authCanByUserId('editOwn.users', this.objectId) &&
            authCan('editOthers.users')
          "
          v-bind="propsButtonTrash"
          :loading="status.loading"
          :disabled="!formRemove"
          :to="{ name: 'users-delete', params: { id: objectId } }"
          data-cy="button-remove"
          >{{ $t("common.remove") }}</v-btn
        >
        <div class="flex-grow-1"></div>
        <v-btn
          v-if="
            authCanByUserId('editOwn.users', this.objectId) ||
            authCan('editOthers.users')
          "
          v-bind="propsButtonAction"
          :loading="status.loading"
          :to="{ name: 'users-change-password' }"
          data-cy="button-change-password"
          >{{ $t("users.changePasswordTitle") }}</v-btn
        >
        <div class="flex-grow-1"></div>
        <v-btn
          v-if="
            authCanByUserId('editOwn.users', this.objectId) ||
            authCan('editOthers.users')
          "
          v-bind="propsButtonAction"
          :loading="status.loading"
          :disabled="!formReadyForSubmit"
          @click="clickUpdate"
          data-cy="button-update"
          >{{ $t("common.update") }}</v-btn
        >
      </div>
    </v-sheet>
  </v-skeleton-loader>
</template>

<script>
import Permissions from "@/modules/auth/mixins/Permissions.mixin.js";
import ComponentStatus from "@/modules/base/componentStatus.mixin";
import UserRolePickerSelect from "@/modules/users/bits/UserRolePickerSelect";
import CountryPickerSelect from "@/modules/base/bits/CountryPickerSelect";
import LanguagePickerSelect from "@/modules/base/bits/LanguagePickerSelect";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  name: "UsersEdit",
  mixins: [Permissions, ComponentStatus],
  components: {
    UserRolePickerSelect,
    CountryPickerSelect,
    LanguagePickerSelect,
  },
  props: {
    objectId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    firstFormClick: true,
    changed: false,

    // Edit proxy form data
    editUserId: "",
    editUserName: "",
    editSurnames: "",
    editName: "",
    editRole: -1,
    editEmail: "",
    editPhone: "",
    editProfilePicturePath: "",
    editDescription: "",
    editAddress: "",
    editCity: "",
    editPostalCode: "",
    editCountryIso3166_a2: "",
    editLanguageIso639_1: "",
  }),
  validations: {
    editUserName: {
      required,
      maxLength: maxLength(100),
    },
    editName: {
      required,
      maxLength: maxLength(100),
    },
    editSurnames: {
      maxLength: maxLength(100),
    },
    editRole: {
      maxLength: maxLength(100),
    },
    editEmail: {
      maxLength: maxLength(100),
    },
    editPhone: {
      maxLength: maxLength(100),
    },
    editProfilePicturePath: {
      maxLength: maxLength(100),
    },
    editDescription: {
      maxLength: maxLength(1000),
    },
    editAddress: {
      maxLength: maxLength(100),
    },
    editCity: {
      maxLength: maxLength(100),
    },
    editPostalCode: {
      maxLength: maxLength(100),
    },
    editCountryIso3166_a2: {
      maxLength: maxLength(100),
    },
    editLanguageIso639_1: {
      maxLength: maxLength(100),
    },
  },
  computed: {
    formDisabled() {
      // if (this.isAdmin) {
      //   return false;
      // }
      return false;
    },
    formRemove() {
      // if (this.isAdmin) {
      //   return true;
      // }
      return true;
    },
    formReadyForSubmit() {
      if (this.status.loading) {
        return false;
      }
      if (this.status.readError) {
        return false;
      }
      // if (this.isAdmin) {
      //   return true;
      // }
      if (this.firstFormClick) {
        return true;
      }
      if (this.$v.$invalid) {
        return false;
      }
      if (!this.$v.$anyDirty) {
        return false;
      }
      return true;
    },
    editOrganisationId() {
      return this.$store.getters["auth/getOrganisationId"];
    },
    editUserNameErrors() {
      const errors = [];
      if (!this.$v.editUserName.$dirty) return errors;
      if (!this.$v.editUserName.required) {
        errors.push(this.$t("common.errors.required"));
      }
      if (!this.$v.editUserName.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editNameErrors() {
      const errors = [];
      if (!this.$v.editName.$dirty) return errors;
      if (!this.$v.editName.required) {
        errors.push(this.$t("common.errors.required"));
      }
      if (!this.$v.editName.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editSurnamesErrors() {
      const errors = [];
      if (!this.$v.editSurnames.$dirty) return errors;
      if (!this.$v.editSurnames.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editRoleErrors() {
      const errors = [];
      if (!this.$v.editRole.$dirty) return errors;
      if (!this.$v.editRole.required) {
        errors.push(this.$t("common.errors.required"));
      }
      if (!this.$v.editRole.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editEmailErrors() {
      const errors = [];
      if (!this.$v.editEmail.$dirty) return errors;
      if (!this.$v.editEmail.required) {
        errors.push(this.$t("common.errors.required"));
      }
      if (!this.$v.editEmail.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editPhoneErrors() {
      const errors = [];
      if (!this.$v.editPhone.$dirty) return errors;
      if (!this.$v.editPhone.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editProfilePicturePathErrors() {
      const errors = [];
      if (!this.$v.editProfilePicturePath.$dirty) return errors;
      if (!this.$v.editProfilePicturePath.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editDescriptionErrors() {
      const errors = [];
      if (!this.$v.editDescription.$dirty) return errors;
      if (!this.$v.editDescription.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editAddressErrors() {
      const errors = [];
      if (!this.$v.editAddress.$dirty) return errors;
      if (!this.$v.editAddress.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editCityErrors() {
      const errors = [];
      if (!this.$v.editCity.$dirty) return errors;
      if (!this.$v.editCity.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editPostalCodeErrors() {
      const errors = [];
      if (!this.$v.editPostalCode.$dirty) return errors;
      if (!this.$v.editPostalCode.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editCountryIso3166_a2Errors() {
      const errors = [];
      if (!this.$v.editCountryIso3166_a2.$dirty) return errors;
      if (!this.$v.editCountryIso3166_a2.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editLanguageIso639_1Errors() {
      const errors = [];
      if (!this.$v.editLanguageIso639_1.$dirty) return errors;
      if (!this.$v.editLanguageIso639_1.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    // 2) When the user id changes we use the store getter to "get" the
    // object that has (or the temporary one that will have) the user data.
    // Note that this is different than the store action used to read the
    // actual data from the backend, they both work "in parallel".
    user() {
      return this.$store.getters["users/readById"](this.objectId);
    },
  },
  watch: {
    // 3) If the user id changes and we have not edit any field we trigger
    // the store action to load the data. Note that this is different than
    // the getter used to display the data, they both work "in parallel".
    objectId: {
      immediate: true,
      handler: function () {
        if (!this.changed) {
          this.setStatusReading();
          this.editUserId = this.objectId;
          this.$store
            .dispatch("users/readById", this.objectId)
            .then((/* result */) => {
              this.setStatusReadSuccess();
            })
            .catch((/* error */) => {
              this.setStatusReadError();
            });
        }
      },
    },
    // 4) In case we load new user data (and we are not in the middle of
    // editing) we update our "proxy" form editable variables to reflect
    // what we have just loaded.
    user: {
      immediate: true,
      handler: function (newValue /* , oldValue */) {
        this.refreshFormData(newValue);
      },
    },
  },
  created() {},
  methods: {
    inputChanged() {
      this.changed = true;
    },
    refreshFormData(value) {
      let newValue = this.user;
      if (value) {
        newValue = value;
      }
      if (!this.changed) {
        this.editName = newValue.name;
        this.editDescription = newValue.description;
        this.editUserName = newValue.userName;
        this.editSurnames = newValue.surnames;
        this.editRole = newValue.role;
        this.editEmail = newValue.email;
        this.editPhone = newValue.phone;
        this.editProfilePicturePath = newValue.profilePicturePath;
        this.editAddress = newValue.address;
        this.editCity = newValue.city;
        this.editPostalCode = newValue.postalCode;
        this.editCountryIso3166_a2 = newValue.countryIso3166_a2;
        this.editLanguageIso639_1 = newValue.languageIso639_1;
      }
    },
    clickUpdate() {
      if (this.firstFormClick) {
        this.firstFormClick = false;
        this.$v.$touch();
      }
      if (!this.$v.$invalid) {
        this.sendUpdate();
      }
    },
    sendUpdate() {
      this.setStatusUpdating();
      let payload = {
        id: this.editUserId,
      };
      // Sending only the data that has changed.
      if (this.editName != this.user.name) {
        payload.name = this.editName;
      }
      if (this.editUserName != this.user.userName) {
        payload.userName = this.editUserName;
      }
      if (this.editSurnames != this.user.surnames) {
        payload.surnames = this.editSurnames;
      }
      if (this.editRole != this.user.role) {
        payload.role = this.editRole;
      }
      if (this.editEmail != this.user.email) {
        payload.email = this.editEmail;
      }
      if (this.editPhone != this.user.phone) {
        payload.phone = this.editPhone;
      }
      if (this.editProfilePicturePath != this.user.profilePicturePath) {
        payload.profilePicturePath = this.editProfilePicturePath;
      }
      if (this.editDescription != this.user.description) {
        payload.description = this.editDescription;
      }
      if (this.editAddress != this.user.address) {
        payload.address = this.editAddress;
      }
      if (this.editCity != this.user.city) {
        payload.city = this.editCity;
      }
      if (this.editPostalCode != this.user.postalCode) {
        payload.postalCode = this.editPostalCode;
      }
      if (this.editCountryIso3166_a2 != this.user.countryIso3166_a2) {
        payload.countryIso3166_a2 = this.editCountryIso3166_a2;
      }
      if (this.editLanguageIso639_1 != this.user.languageIso639_1) {
        payload.languageIso639_1 = this.editLanguageIso639_1;
      }

      this.$store
        .dispatch("users/update", payload)
        .then((/* result */) => {
          this.setStatusUpdateSuccess();
          this.$store.commit("status/showSuccess");
          this.changed = false;
          // this.refreshFormData();
        })
        .catch((/* error */) => {
          // Setting the appropriate error markers from the server response.
          this.$v.$touch();
          this.setStatusUpdateError();
          this.$store.commit("status/showError");
        });
    },
  },
};
</script>
